.testimonals_container {
  background-color: #ffecb8;
  position: relative;
}

.testimonals_container .cat_foots_image {
  position: absolute;
  top: 50px;
  right: 0;
  width: 100px;
  /* display: none; */
}

.testimonals_container .cat_foots_image img {
  width: 100%;
}

.testimonals_container .testimonal_title {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.testimonals_container .testimonal_title .title {
  text-align: center;
  color: #ff9100;
  font-size: 45px;
  border-bottom: 4px solid #ff9100;
  /* margin-top: -100px; */
  font-weight: bolder;
}

.testimonals_container .testimonials {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 25px;
}

.testi_comma{
  fill:#FF9100;
  font-size: 30px;
  display: inline-block;
}

.testimonals_container .testimonials .testi {
  display: flex;
  padding-inline: 50px;
  flex-direction: row;
  width: 100vw;
}

.testimonals_container .testimonials .testi .user_image {
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonals_container .testimonials .testi .user_image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testi_divider {
  min-width: 2.5px;
  border-radius: 100px;
  height: 200px;
  background-color: #ff9100;
  margin-inline: 30px;
}

.testimonals_container .testimonials .testi .testi_content .testi_body,
.testimonals_container .testimonials .testi .testi_content .testi_user_name {
  /* width: 100px; */
  color: #908070;
  font-size: 25px;
}

.testimonals_container .testimonials .testi .testi_content .testi_user_name {
  margin-top: 25px;
}

.testimonals_container .testimonials .testi .testi_content .testi_user_slogan {
  color: #908070;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .testimonals_container .testimonal_title .title {
    font-size: calc(10px + 2.3vw);
    border-bottom: 2px solid #ff9100;
  }

  .testimonals_container .testimonials .testi {
    padding-inline: 15px;
  }
  
.testimonals_container .testimonials .testi .user_image {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
}


.testi_divider {
  min-width: 2px;
  height:100px;
  margin-inline: 15px;
}

.testi_comma{
  font-size: 15px !important;
  color: red;
}

.testimonals_container .testimonials .testi .testi_content .testi_body,
.testimonals_container .testimonials .testi .testi_content .testi_user_name {
  /* width: 100px; */
  font-size: 10px;
  font-size: calc(5px + 1.5vw);
  
  }
  
.testimonals_container .testimonials .testi .testi_content .testi_user_name {
  margin-top: 10px;
}

.testimonals_container .testimonials .testi .testi_content .testi_user_slogan {
  color: #908070;
  font-size: 8px;
font-size: calc(5px + .6vw);
}

.testi_bones{
  transform: scale(0.5);
}


.testimonals_container .cat_foots_image {
  width: 60px;
  top:30px;
  width: calc(30px + 3vw);
  /* display: none; */
}
  
}




/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .testimonals_container .testimonal_title .title {
    font-size: calc(10px + 2.3vw);
    border-bottom: 3px solid #ff9100;
  }

  .testimonals_container .testimonials .testi .user_image {
    max-width: 100px;
    max-height: 100px;
  }
    
.testi_comma{
  font-size: 20px !important;
  color: red;
}


.testi_bones{
  transform: scale(0.7);
}
  
  .testi_divider {
    min-width: 3px;

    margin-inline: 20px;
  }

  .testimonals_container .testimonials .testi .testi_content .testi_body,
.testimonals_container .testimonials .testi .testi_content .testi_user_name {
  /* width: 100px; */
  font-size: 15px;
  font-size: calc(10px + 1.5vw);
}

.testimonals_container .testimonials .testi .testi_content .testi_user_name {
  margin-top: 15px;
}

.testimonals_container .testimonials .testi .testi_content .testi_user_slogan {
  color: #908070;
  /* font-size: 12px; */
  font-size: calc(9px + .6vw);

}
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
