.features_container {
  background-color: #ffecb8;
  position: relative;
  /* height: 300px; */
}

.features_container .feature_title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.features_container .feature_title .title {
  text-align: center;
  color: #ff9100;
  font-size: 45px;
  border-bottom: 4px solid #ff9100;
  /* margin-top: -100px; */
  font-weight: bolder;
}

.dogBack_image {
  position: absolute;
  right: 0;
  top: 0;
  width: 226px;
  transform: translateY(-30%);
}

.dogBack_image > img {
  width: 100%;
}

.cat_tail_image {
  position: absolute;
  left: -2px;
  top: 60%;
  width: 130px;
  transform: translateY(-50%);
}

.cat_tail_image img {
  width: 100%;
}

.features_content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-inline: 20px;
}

.features_content .content {
  display: flex;
  justify-content: center;
}

.features_content .content .feature_images {
  display: flex;
  align-items: center;
  gap: 1px;
}

.feet_divider {
  width: 2.5px;
  border-radius: 100px;
  height: 180px;
  background-color: #ff9100;
  margin-inline: 30px;
}

.features_content .content .feature_images .image_1 {
  width: 300px;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
}

.features_content .content .feature_images .small_images {
  width: 150px;
  height: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1px;
}

.features_content .content .feature_images .image_2,
.features_content .content .feature_images .image_3 {
  width: 100%;
  height: 70%;
  border-radius: 10px;
  overflow: hidden;
}

.features_content .content .feature_images .image_3 {
  height: 30%;
}

.feature_images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* feature info styles */

.feature_info {
  padding-left: 30px;
}

.feature_info .feat_title {
  font-size: 30px;
  color: #705c46;
  font-weight: bolder;
  font-family:"Chelsea Market" !important;
}

.feature_info .feat_body {
  font-size: 17px;
  color: #705c46;
  margin-top: 0px;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  
.features_container .feature_title .title {
  font-size: 16px;
  font-size: calc(10px + 2.3vw);
  border-bottom: 2px solid #ff9100;
  /* margin-top: -100px; */
}

.dogBack_image {
  top: 30px;
  width: 80px;
}

.cat_tail_image {
  left: -2px;
  top: 420px;
  width: 60px;
  transform: translateY(-50%);
}

.features_content .content .feature_images .image_1 {
  width: 80px;
  height: 120px;
}

.features_content .content .feature_images .small_images {
  width: 60px;
  height: 120px;
}
.features_content .content .feature_images .image_2{
  width: 100%;
  height: 100%;
}

.features_content .content .feature_images .image_3 {
  height: 30%;
  display: none;
}


.feature_info .feat_title {
  font-size: 30px;
  color: #705c46;
  font-weight: bolder;
  font-family:"Chelsea Market" !important;
}

.feature_info {
  padding-left: 00px;
}

.feature_info .feat_title {
  font-size: 12px;
}

.feature_info .feat_body {
  font-size: 11px;
  margin-top: 5px;
}

.feet_divider {
  width: 2px;
  height:120px;
  margin-inline: 10px;
}

 }
 
 
 /* Medium devices (tablets, less than 992px) */
 @media (min-width: 768px) and ( max-width: 991.98px) {
  .features_container .feature_title .title {
    font-size: 30px;
    border-bottom: 3px solid #ff9100;
    /* margin-top: -100px; */
  }
  
  .dogBack_image {
    top: 40px;
    width: 120px;
  }
  
  .cat_tail_image {
    left: -2px;
    top: 420px;
    width: 120px;
  }
  
  .features_content .content .feature_images .image_1 {
    width: 140px;
    height: 120px;
  }
  
  .features_content .content .feature_images .small_images {
    width: 70px;
    height: 120px;
  }
  
  .features_content .content .feature_images .image_2{
    width: 100%;
    height: 100%;
  }
  
  .features_content .content .feature_images .image_3 {
    height: 30%;
    display: none;
  }
  
  

  
  .feature_info {
    padding-left: 00px;
  }
  
  .feature_info .feat_title {
    font-size: 17px;
  }
  
  .feature_info .feat_body {
    font-size: 16px;
    margin-top: 5px;
  }
  
  .feet_divider {
    width: 2px;
    height:120px;
    margin-inline: 10px;
  }
 }
 
 /* Large devices (desktops, less than 1200px) */
 @media ( min-width: 992px) and (max-width: 1199.98px) {
   
   
 }
 
 /* Extra large devices (large desktops, less than 1400px) */
 @media (max-width: 1399.98px) {
 }
 
 /* Extra extra large devices (larger desktops, less than 1600px) */
 @media (max-width: 1599.98px) {
 }
 


