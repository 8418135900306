.start_page_container {
  overflow-x: hidden;
}


.call_whatsapp {
  font-size: 40px;
  cursor: pointer;
  transition: 0.3s;
}

.call_whatsappDiv {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 25px;
  right: 12px;
  background-color: #EE701E;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;

  transition: 0.3s;
}

.call_whatsappDiv:hover {
  background-color: #dd600f;
}