.FAQ_container{
  background-color: #FF9100;
  position: relative;
  /* padding-bottom: 30px; */
  
}
.fear_cat_image{
  position: absolute;
  top:100px;
  left: 0px;
  width: 400px;

  }

  .fear_cat_image img{
    width: 100%;
}

.FAQ_container .black_dog_image{
  position: absolute;
  right: 40px;
  bottom:30px;
  width: 300px;
  border-bottom:4px dotted rgb(230, 230, 230) ;

  
}
.FAQ_container .black_dog_image img{
  width: 100%;
}


.FAQ_container .FAQ_title {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.FAQ_container .FAQ_title .title {
  text-align: center;
  color: #FBF5E7;
  font-size: 45px;
  border-bottom: 4px solid #FBF5E7;
  /* margin-top: -100px; */
  font-weight: bolder;
}

.FAQ_container .questions_container{
  padding-inline: 50px ;
  /* margin-bottom: 60px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  /* margin-bottom: 100px; */
  width: calc(100% - 300px);
}

.FAQ_container .questions_container .question{
  display: flex;
  align-items: center;
  gap: 20px;

}

.FAQ_container .questions_container .question .dog_foot_print_image{
  width: 30px;
}
.FAQ_container .questions_container .question .dog_foot_print_image img{
  width: 100%;
}

.FAQ_container .questions_container .question .question_text{
  color: #FBF5E7 ;
  font-size: 20px;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
.FAQ_container .FAQ_title {
  gap: 30px;
}

.FAQ_container .FAQ_title .title {
  font-size: calc(10px + 2.3vw);
  border-bottom: 2px solid rgb(230, 230, 230);
}

.FAQ_container .questions_container{
  padding-inline: 20px ;
  gap: 10px;
  margin-top: 20px;
  width: calc(100%);
}

.FAQ_container .questions_container .question{
  gap: 15px;
}

.FAQ_container .questions_container .question .dog_foot_print_image{
  min-width: 20px;
  max-width: 20px;
}

.FAQ_container .questions_container .question .question_text{
  font-size: 10px;
  font-size: calc(5px + 2.2vw);
}

.FAQ_container .black_dog_image{
    display: none;
    border: none;

}



.fear_cat_image{
  top:50px;
  width: 250px;

  width: calc(100px + 10vw);

  }

}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {

.FAQ_container .FAQ_title {
  gap: 30px;
}

.FAQ_container .FAQ_title .title {
  font-size: calc(10px + 2.3vw);
  border-bottom: 3px solid rgb(230, 230, 230);
  font-size: calc(10px + 2.3vw);
}

.FAQ_container .questions_container{
  padding-inline: 20px ;
  gap: 15px;
  margin-top: 25px;
  width: calc(100%);
}

.FAQ_container .questions_container .question{
  gap: 20px;
}

.FAQ_container .questions_container .question .dog_foot_print_image{
  min-width: 25px;
  max-width: 25px;
}

.FAQ_container .questions_container .question .question_text{
  font-size: 10px;
  font-size: calc(5px + 1.8vw);
}

.FAQ_container .black_dog_image{
  display: none;
  border: none;
}

.fear_cat_image{
  top:70px;
  width: 320px;
  }

}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}