
.header_container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999999999 !important;
}

.header1_container {
  padding: 24px 40px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  position: relative;
}

.header_1_content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header_help{
  font-size: 15px ;
  font-weight: bolder;
  cursor: pointer;
}

.left_side_btn{
  color: black;
  fill: #222;
  width: 52px;
  z-index: 100000000000 !important;
  /* position: absolute;
  left: 10px; */
  cursor: pointer;

}

.left_side_btn.open .icon-hamburger__open_top{
  transform: rotate(45deg)  translate(0px, -4px);
  z-index: 10000;
  transform-origin: center;
}

.left_side_btn.open .icon-hamburger__open_bottom{
  transform: rotate(-45deg)  translate(0px, 5px);
  z-index: 10000;
  transform-origin: center;
}


/* .side_nav_container.open  */

.icon-hamburger__open_top,
.icon-hamburger__open_bottom{
  transition: .4s ease;
  
}



.header_1_content .header1_image{
  width: 100px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
}

.header_1_content .header1_image img{
  width: 100%;
}


.header_1_content .header1_right_side{
  display: flex;
  align-items: center;
  gap: 10px;
}

.header_1_content .header1_right_side  div{
  width:52px;
  cursor: pointer;
}


/* HEADER_2 STYLE */

.header2_container{
  width: 100%;
  background-color: #EE701E;
  text-align: center;
  color: #ffffff;
  padding-block: 4px;
  font-weight: bolder;
}






/* Optional: Add some additional styles for responsiveness */
@media (max-width: 768px) {
  .header1_container,
  .header2_container {
    padding: 10px 15px;
  }

  .header1_right_side div{
    width: 30px !important;
  }

  .header1_container svg{
    width: 30px;
  }

  .header1_container .left_side {
    gap: 7px;
  }

  .header1_container .right_side {
    gap: 15px;
  }

  .header1_container .left_side .header1_item {
    font-size: 9px;
  }

  .header1_container .header1_icon {
    font-size: 16px;
  }
  
  .header_help{
    font-size: calc(10px + 1.2vw );
  }

  
.header_1_content .header1_image{
  width: 70px;
}

.header_1_content .header1_image img{
  width: 100%;
}


  .header1_container .custom_badge {
    position: absolute;
    width: 15px;
    height: 15px;
    font-size: 8px;
  }

  .header2_container .nav_links {
    display: none;
  }

  .header2_container .collapse_btn {
    display: block;
  }

  .header2_container img {
    width: 70px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}

/* End Header_2 Styles */
