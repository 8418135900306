
.downloadApp_container {
  background-color: #FFECB8;
  position: relative;
}


.downloadApp_container .content{
  display: flex;
  align-items: center; 
  width: 100%;
  margin-top: -50px;
  
}
.downloadApp_container .content div{
  width: 100%;
}

.downloadApp_container .content .left{
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadApp_container .content .left img{
  width: 300px;
  
}

.downloadApp_container .content .right{
  
}

.downloadApp_container .content .right h3{
  font-size: 40px;
  font-weight: bolder;
  color :rgb(37, 37, 37)
}

.downloadApp_container .content .right h5{
  font-size: 20px;
  color :rgb(76, 76, 76);
  margin-top: 30px;
  font-weight: bolder;
  
}
  .downloadApp_container .content .right .stores_images{
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    
  }

  .downloadApp_container .content .right .googlePlayImage,
  .downloadApp_container .content .right .appStoreImage{
    width: 150px;
  }

  .downloadApp_container .content .right .googlePlayImage img,
  .downloadApp_container .content .right .appStoreImage img{
    width: 100%;
  }

  
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .downloadApp_container .content{
    margin-top: -20px;
    
  }
  
  .downloadApp_container .content .left img{
    min-width: calc(100px + 5vw);
    
  }

  .downloadApp_container .content .right h3{
    font-size: calc(10px + 1.2vw);

    font-weight: bolder;
    color :rgb(37, 37, 37)
  }

  .downloadApp_container .content .right h5{
    font-size: calc(10px + 1.3vw);
    margin-top: 20px;
  }

  .downloadApp_container .content .right .stores_images{
    flex-direction: column;
    align-items: start;

    gap: 20px;
    margin-top:15px;
  }

  .downloadApp_container .content .right .googlePlayImage,
  .downloadApp_container .content .right .appStoreImage{
    width: 100px;
    width: calc(80px + 7vw);
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
 
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
