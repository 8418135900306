.findpet_container {
  padding-inline: 80px;
  margin-top: 30px;
  margin-block: 100px;
}

.findpet_container .fb_content {
  background-color: #ff6c0c;
  border: 1px solid #c3c3c3;
  border-radius: 1000px;
  display: flex;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 0 0 10px rgb(199, 199, 199);
}

.findpet_container .fb_content .fp_left_side {
  display: flex;
  align-items: center;
  padding: 15px;
}

.findpet_container .fb_content .fp_right_side {
  justify-content: center;
  display: flex;
  align-items: end;
}

.findpet_container .fb_content {
  display: flex;
  background-color: rgba(242, 242, 242, 0.822);
  border: 1px solid #c3c3c3;
  border-radius: 1000px;
  justify-content: space-between;
}

.findpet_container .fb_content .fp_left_side .image {
  width: 100px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 50%;
}

.findpet_container .fb_content .fp_left_side .image img {
  max-width: 100%;
  min-width: 100%;
}

.fb_jubject .fb_title {
  font-size: 30px;
  font-weight: bolder;
}

.fb_jubject .fb_title span {
  color: #1f1f1f;
}

.fp_dog_image {
  width: 200px;
  margin-left: 50px;
}

.fp_dog_image img {
  min-width: 100%;
  max-width: 100%;
}

.fb_abs_btn {
  position: absolute;
  bottom: 0px;
  transform: translateY(70%);
  left: 150px;
  color: #ffff !important;
  padding: 10px 25px;
  width: 300px;
  background-color: #ff6c0c;
  border-radius: 10000px;
  font-size: 20px;
  color: #ff6c0c;
  cursor: pointer;
  border: 1px solid #ff6c0c;
  transition: 0.2s ease;
}

.fb_abs_btn_start {
  transform: translateX(-35px);
  color: #ffff !important;
  padding: 13px 20px;
  /* width: 300px; */
  background-color: #ff6c0c;
  border-radius: 10000px;
  font-size: 15px;
  color: #ff6c0c;
  cursor: pointer;
  border: 1px solid #ff6c0c;
  transition: 0.2s ease;
  margin-left: auto;
}

.fb_abs_btn:hover {
  background-color: #ff6c0c;
  color: #fff;
  box-shadow: 0 0 10px rgb(201, 201, 201);
}

.fb_info {
  width: 400px;
}

.stored_pets {
  background-color: #ff6c0c;
  padding: 30px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stored_pets_content {
  width: calc(70%);
  padding: 0 20px;
}

.fb_abs_btn_start_mobile {
  display: none;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .findpet_container {
    padding-inline: 15px;
  }

  .fb_content {
    margin-bottom: 10px !important;
  }

  .findpet_container .fb_content .fp_left_side {
    padding: 10px;
    padding-block: none;
  }

  .findpet_container .fb_content .fp_right_side {
    display: none;
  }

  .findpet_container .fb_content .fp_left_side .image {
    width: 90px;
    margin-right: 10px;
  }

  .fb_jubject .fb_title {
    font-size: calc(7px + 1.2vw);
  }

  .fb_info {
    width: auto;
    font-size: 9px;
    font-size: calc(5px + 1.2vw);
  }

  .fp_dog_image {
    display: none;
  }

  .fb_abs_btn {
    position: absolute;
    bottom: 0px;
    transform: translateY(70%);
    left: 20px;
    color: #ffff !important;
    padding: 10px 25px;
    width: auto;

    font-size: 13px;
  }

  /* .fb_abs_btn_start {
    display: none;

  } */

  .fb_search_content {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .fb_abs_btn_start_mobile {
    display: inline;
    color: #ffff !important;
    padding: 12px 20px;
    /* width: 300px; */
    background-color: #ff6c0c;
    border-radius: 10000px;
    font-size: 15px;
    color: #ff6c0c;
    cursor: pointer;
    border: 1px solid #ff6c0c;
    transition: 0.2s ease;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .findpet_container {
    padding-inline: 15px;
    height: 40vh;
    /* margin-top: auto; */
  }

  .findpet_container .fb_content .fp_left_side {
    padding: 10px;
    padding-block: none;
  }

  .findpet_container .fb_content .fp_right_side {
    /* display: none; */
  }

  .findpet_container .fb_content .fp_left_side .image {
    width: 90px;

    margin-right: 10px;
  }

  .fb_jubject .fb_title {
    font-size: calc(7px + 1.2vw);
  }

  .fb_info {
    width: 300px;
    font-size: 9px;
    font-size: calc(5px + 1.2vw);
  }

  .fp_dog_image {
    width: 200px;
    margin-left: 70px;
  }

  .fp_dog_image.start_page {
    display: none;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .fb_content.aos-init.aos-animate {
    width: 100% !important;
  }

  .fp_dog_image {
    width: 150px;
    margin-left: 0px;
  }
}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
