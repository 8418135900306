.about_benefit_container {
    background-color: #eb741c;
    padding:40px;
    margin: 0px;
}

.about_benefit_container h1 {
    color :white;
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-size: 50px;
     font-weight: bolder;
}

.about_benefit_container h1 span {
    color:#000;
}

.about_benefit_page {
    display: flex;
    flex-direction: column;
    gap:20px;
    margin: 50px 0px;
}

.about_benefit_card {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 30px !important;
    max-width: 800px;
    margin: 20px auto;
}

.about_benefit_card_img {
   display: grid;
   grid-template-columns: repeat(2,1fr);
   align-items: center;
    gap: 20px;
}

.about_benefit_card_img img {
    width: 150px;
}

.about_benefit_card_img  p {
    font-weight: bold;
}

.about_benefit_card_text {
    width: 80%;
}

.about_benefit_card_text p:first-of-type {
    color: #000;
    font-weight: bold;
}

.about_benefit_card_text p:last-of-type {
    color: white;
}

@media (max-width:710px) {
    .about_benefit_card {
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: centers;
    }

    .about_benefit_card_text {
        margin: 0px auto;
        text-align: center;
    }

    .about_benefit_card_img {
       grid-template-columns: 1fr;
        gap: 10px;
        margin: 0px auto;
        text-align: center;
    }
}

@media (max-width:630px) {
    .about_benefit_container h1 {
        font-size: 35px;
    }
}

@media (max-width:430px) {
    .about_benefit_container h1 {
        font-size: 30px;
    }
}

@media (max-width:410px) {
    .about_benefit_container {
        padding: 40px 30px;
    }
}

@media (max-width:390px) {
    .about_benefit_container {
        padding: 40px 20px;
    }
}