.credit_card_container {
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;
}

.credit_card_header {
  border-radius: 7px 7px 0  0 ;
  display: flex;
  padding: 10px;
  padding-block: 15px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(241, 91, 91);
}


.credit_card_content{
    padding: 15px;

}
.credit_card_inputs {
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 20px;
  gap: 20px;
}


.credit_card_input input{
  border: 1px solid red;
  width: 100%;
  border-radius: 5px;
  transition: .2s ease;
  padding: 10px;
  border: 1px solid #adadad;
  outline:none
}

.credit_card_input input:focus{
  box-shadow: 0 0 0px 2px rgba(234, 165, 26, 0.692);
}


.credit_card_input.input_1 {
  grid-area: 1 / 1 / 2 / 5;
}
.credit_card_input.input_2 {
  grid-area: 2 / 1 / 3 / 3;
}
.credit_card_input.input_3 {
  grid-area: 2 / 3 / 3 / 5;
}
.credit_card_input.input_4 {
  grid-area: 3 / 1 / 4 / 5;
}
