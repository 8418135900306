.accordion {
  overflow: hidden;
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.accordion-item {
  width: 100%;
  background-color: #f8f7fa !important;
  border: 1px solid #f8f7fa !important;
}

.accordion-item:first-child {
  border-top: none;
}

.title {
  cursor: pointer;
  padding: 15px;
  background: #f8f7fa;
  display: flex;
  align-items: center;
  gap: 7px;
  align-items: center;
  font-weight: bold;
}

.title p {
  font-size: 17px;
  margin: auto 0px;
  text-align: start !important;
}

.title.active {
  background: #e9e9e9;
}

.acc_icon {
  background-color: #121212;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  padding: 0px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 20px auto 0px;
  transition: transform 0.3s ease;
  padding-bottom: 4px;
  width: 35px;
  height: 35px;
}

.acc_icon.plus:before {
  content: '+';
  height: fit-content;
}

.acc_icon.close:before {
  content: '-';
  height: fit-content;
}

.content {
  padding: 0px 15px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease, padding 0.3s ease;
  background-color: #f8f7fa;
}

.content p {
  margin: 0px 40px;
  margin-left: 50px !important;
}

.accordion-item.active .content {
  padding: 0px 15px 35px;
  padding-left: 15px !important;
  max-height: 200px;
}


@media (max-width:500) {
  .title p {
    font-size: 15px;
  }
}