.foto_page {
    background-color: white;
    width: 186px;
    height: 200px;
    box-shadow: 0px -3px 6px 2px rgba(0,0,0,0.2);
    /* padding: 10px 0px; */
}

.foto_page img  {
    width: 100%;
    height: 160px;
    object-fit: contain;
}

.foto_page button {
    width: 100%;
    color: white;
    padding: 10px 0px;
    background-color: #4e73df;
    margin: 0px 0px;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.2);
}

/* .btn {
    background-color: #4e73df !important;
    border: 1px solid #4e73df !important;
    padding: 3px 10px;
    border-radius: 5px;
    color: white !important;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
} */