.pet-card {
  background-color: #005f6b;
  color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dni {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.pet-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.pet-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.pet-info p {
  font-size: 0.9rem;
  margin: 5px 0;
  margin-bottom: 30px;
}

.pet-info p strong {
  font-weight: bold;
  color: #e0e0e0;
}

.action-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.icon {
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.icon:hover {
  color: #ffdd57;
}

.ant-modal-root {
  z-index: 1992839128 !important;
  position: relative;
}

.action-icons svg {
  font-size: 55px !important;
}

.action-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  position: absolute;
  right: 10%;
  top: 50%;
  flex-direction: column;
  font-size: 25px !important;
}

input.search-input {
  border: 1px solid;
  width: 80%;
  padding: 11px 0;
}

input {
  outline: none !important;
}
