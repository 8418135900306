.ownership_container {
  padding-inline: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 50px;
  position: relative;
}

.peru_map_image{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
}

.peru_map_image img{
  width: 100%;
}



.ownership_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  gap:40px;
}


.ownership_content > div {
  width: 100%;
}

.os_left_side {
  display: flex;
  align-items: center;
  justify-content: end;
}

.os_left_side .image {
  width: 400px;
  overflow: hidden;
}

.os_left_side .image img {
  max-width: 100%;
  min-width: 100%;
}



.os_right_side {
  /* padding-inline: 40px; */
  /* margin-left: 50px; */
}

.os_right_side h5 {
  color: #222;
  font-size: 20px;
  font-weight: bolder;
  margin: 0;
  padding: 0;
}

.text_orange {
  color: #ee701e !important;
}

.os_right_side .os_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  margin-top: 15px;
}

.os_right_side .os_content .os_content_sec {
  font-size: 13px;
  color: #222;
}


.oval_button.os_oval{
  width: fit-content;
  padding: 0;
  margin-top: 20px;
}

.oval_button.os_oval .oval_btn_text{
  font-size: 14px;
  width: 100px;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

  .ownership_container{
    padding-inline: 10px;
    margin-block: 20px;
    
  } 
  
  .ownership_content {
  flex-direction: column;
  width: calc(100% );
}


.os_left_side {
  justify-content: center;
  margin-bottom: 10px;
}
.os_left_side .image {
  width: 200px;
  padding: 6px;
}

.os_right_side {
  padding-inline: 10px;
}

.os_right_side h5 {
  font-size: 13x;
  /* text-align: center; */
}


.os_right_side .os_content {
  gap: 10px;
  width: 100%;
  padding-right: 20px;
}

.oval_button.os_oval{
  transform: scale(0.9);
}

.os_right_side .os_content .os_content_sec {
  font-size: 10px;
  /* text-align: center; */
}

.peru_map_image{
  width: 130px;
}

/* .oval_button.os_oval{
  width: 100%;
  padding:0;
  margin-top: 20px;
  width: 100%;
}
.oval_button.os_oval svg{
  margin-inline: auto;

} */
  /* 
  } */

/* .oval_button.os_oval .oval_btn_text{
  font-size: 14px;
  width: auto;
  text-align: start;
} */

 
}


/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .ownership_container{
    padding-inline: 10px;
    margin-block: 20px;
    
  } 
  


.os_left_side {
  justify-content: center;
  margin-bottom: 10px;
}

.os_left_side .image {
  width: 300px;
  padding: 6px;
}

.os_right_side {
  padding-inline: 10px;
}

.os_right_side h5 {
  font-size: 13x;
}


.os_right_side .os_content {
  gap: 10px;
  width: 200px;
}

.os_right_side .os_content .os_content_sec {
  font-size: 13px;
}

.oval_button.os_oval{
  margin-top: 20px;
}

 
.peru_map_image{
  width: 120px;
}


.os_right_side .os_content {
  width: auto;
}


}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
 
}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
