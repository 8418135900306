

.form_card{
    margin: 20px;
    border-color: #e3e6f0;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    border-radius: 0.35rem;
    background-color: white;
}



.form_card_header{
  background-color:  #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
  padding: 10px 1.25rem;
  display: flex;
  gap: 5px;
  align-items: center;
  color: #4e73df;
  font-weight: 700;
  border-top-left-radius: .35rem; 
  border-top-right-radius: .35rem; 
}

.form_card_header.drawer{
cursor: pointer;
user-select: none;

}

.form_card_header.drawer.close{
  border-radius: .35rem; 
}

.form_card_header .headerIcon{
  font-size: 20px;
}
.header_drawer_icon.open{

  transform: rotate(0);
}

.header_drawer_icon{
  transform: rotate(-180deg);
  transform-origin: center;
  font-size: 25px;
  color: #9f9f9f;
} 

.form_card_header h4{
  font-size: 15px;
  margin: 0;
  flex-grow: 1;
}


.form_card .form_card_body{
  padding: 1.25rem;
}



/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .form_card{
    margin: 0px;
}

  
  
  }
  
  /* Medium devices (tablets, less than 992px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }
  
  /* Large devices (desktops, less than 1200px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
  }
  
  /* Extra large devices (large desktops, less than 1400px) */
  @media (max-width: 1399.98px) {
  }
  
  /* Extra extra large devices (larger desktops, less than 1600px) */
  @media (max-width: 1599.98px) {
  }
  
  
  