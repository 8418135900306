
.end_section_container{
  margin-top: 20px;
}

.rump_logos{
  margin-bottom: 50px;
  display: flex;
  width: 500px;
  height: 200px;
  align-items: center;
  margin-inline: auto;
  justify-content: center;

}

.rump_logos .image{
  /* height: 170px; */
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rump_logos .image img{
  width: 100%;
}

  
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .rump_logos{
    margin-top: 20px;
  margin-bottom: 25px;
  }

  .rump_logos .image{
    /* height: 120px; */
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }


}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}

