.notiRump_container {
  background-color: #fff;
  position: relative;
}

.noti_swiper {
  /* padding-inline: 30px ; */
}

.notiRump_container .title_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notiRump_container .title_image img {
  width: 700px;
  margin-bottom: 20px;
}

.noti_card {
  height: 700px;
  width: 100%;
  position: relative;
  /* border-radius: 20px 20px 0 0; */
  overflow: hidden;
  cursor: grab;
}

.noti_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noti_details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1313137a;
  color: #dfdfdf;
  display: flex;
  /* border-radius: 10px 10px 0 0 ; */
  /* box-shadow:  0 0 10px rgba(45, 45, 45, 0.728); */

  flex-direction: column;
  justify-content: center;
  /* transform: translateY(calc(100% - 80px)); */
  padding: 10px 20px;
  /* transition: 0.3s ease; */
  /* backdrop-filter: blur(10px); Apply the blur effect */
}

.get_it {
  color: #fff;
  background-color: #222;
  border: none;
  padding: 20px 20px;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 20px;
  cursor: pointer;
}

.noti_details h4 {
  font-size: 19px;
}

.noti_details h3 {
  font-size: 22px;
  font-weight: bolder;
}

.animated_noti {
  position: absolute;
  bottom: 0%;
  /* height: 300px; */
  left: 0;
  right: 0;
  background-color: #8f8f8f;
  color: #dfdfdf;
  display: flex;
  /* border-radius: 10px 10px 0 0 ; */
  /* box-shadow:  0 0 10px rgba(45, 45, 45, 0.728); */

  flex-direction: column;
  justify-content: start;
  transform: translateY(calc(100%));
  /* padding: 10px 20px; */
  padding: 0;
  transition: 0.6s ease;
  opacity: 0;
  /* backdrop-filter: blur(10px); Apply the blur effect */
}

.animated_noti .animal_type_title {
  font-weight: bolder;
  padding: 10px 20px;
}

.animated_noti .noti_content p {
  font-weight: bolder;
  margin-top: 20px;
  padding: 0px 20px;
}

.noti_card:hover .animated_noti {
  transform: translateY(0);
  opacity: 1;
}

/* Optional: Add some additional styles for responsiveness */
@media (max-width: 768px) {
  .custom-prev,
  .custom-next {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .notiRump_container .title_image img {
    width: 200px;
    margin-bottom: 20px;
  }

  .noti_details h4 {
    font-size: 14px;
  }
  
  .noti_details h3 {
    font-size: 16px;
    /* font-weight: bolder; */
  }

  .animated_noti .noti_content p {
    /* font-weight: bolder; */
    margin-top: 5px;
    font-size: 15px;
    padding: 0px 20px;
  }

  
.get_it {
    padding: 12px 20px;
    font-size: 20px;
    margin-top: 20px;
    cursor: pointer;
}





}


/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {


}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
