.about-responsibility-container {
   padding: 10px 50px;
   margin: 20px auto;   
   max-width: 1100px;
   width: 100%;

}

.about-responsibility-container {
    /* display: flex; */
    /* align-items: center; */
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 100px;
}

.about-responsibility-container img{ 
    width: 500px;
    object-fit: cover;
    margin: 0px auto;
}

.about-responsibility-container .about-responsibility-card {
    color: #222;
    width: fit-content;
    font-weight: bolder;
}

.about-responsibility-page h2 {
    margin-bottom: 20px;
    color: #eb741c;
    font-size: 40px;
    line-height: 1;
    font-weight: bolder;
}

.about-responsibility-page {
    /* width: 400px;
    margin: 20px auto; */
}

@media (max-width:970px) {
 .about-responsibility-container {
    padding: 10px 35px;
 }   
}

@media (max-width:930px) {
    .about-responsibility-page h2 {
        font-size: 35px;
    }
}

@media (max-width:880px) {
    .about-responsibility-container {
        grid-template-columns: 1fr;
    }

    .about-responsibility-container img {
        grid-row: 1;
        width: 100%;
        margin: 20px 0px;
    }
}

@media (max-width:500px) {
    .about-responsibility-container {
        padding: 10px 20px;
    }

    .about-responsibility-page h2 {
        font-size: 30px;
    }
}


@media (max-width:400px) {
    .about-responsibility-page h2 {
        font-size: 25px;
    }
}