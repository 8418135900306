.id_button{
  border: 1px solid #fff;
  padding: 10px 20px ;
  border-radius: 300px;
  font-size: 20px;
  outline: none;
  margin-top: 10px;
  transition: .2s ease;
  color: #FFF;
}


.id_button:hover{
  
  border: 1px solid #fff;
  color: #ff9100;
  background-color: #fff;
}

.aboutUs_container{

}

.aboutUs_conent{
  width: 100%;
  border-left: 3px solid #fff;
  padding-left:20px;
}

.aboutUs_title{
  border-bottom: 3px solid #FFF;
}

.aboutUs_conent p {
  font-size: 25px;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
 .aboutUs_container{

 }

 .aboutUs_title{
  font-size: 20px;
  border-bottom: 2px solid #FFF;

 }

 .aboutUs_catImage{
  display: none;
}

.aboutUs_catImage{
  display: none;
}

.aboutUs_conent{
  border: none;
  padding: 20px;
 }

 .aboutUs_conent p {
  font-size: 15px;
 }
 .aboutUs_btn{
  font-size: 12px;
  /* font-weight: bolder; */
  padding: 5px 10px;
 }
 
}


/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and ( max-width: 991.98px) {
  .aboutUs_container{

  }
 
  .aboutUs_title{
   font-size: 30px;
   border-bottom: 3px solid #FFF;
 
  }
 


 
 .aboutUs_conent{
   padding: 20px;
  }
 
  .aboutUs_conent p {
   font-size: 17px;
  }

  .aboutUs_btn{
   font-size: 12px;
   font-weight: bolder;
   padding: 5px 10px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media ( min-width: 992px) and (max-width: 1199.98px) {
  
  
}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
