.feedback_container {
  background-color: #fff;
  }
  
  .feedback_container .feedback_content{
    padding: 0 50px;
    display: flex;
    align-items: center;
    

}
  
.feedback_container .left ,
.feedback_container .right {
  width: 50%;
}

.feedback_container .feedback_title .title {
  font-size: 40px;
  font-weight: bolder;
  color: #222222;
}

.feedback_container .left p {
  color: rgb(77, 77, 77);
  font-size: 15px;
  }
  
  .feedback_container .left .title_2 span{
    color: #f87800;
  }

  .feedback_btn{
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 15px;
    border: 2px solid #f87800 ;
    border-radius: 6px;
    color: #f87800;
    font-weight: bolder;
    transition: .2s ease;
  }

  .feedback_btn:hover{
    background-color: #f87800;
    color: #fff;
  }


  /* feedback card */


.feedback_swiper{
  border: 1px solid #b0b0b0;
  border-radius: 10px;
  }

  .feedback_card{
  padding: 30px;
  background-color: #fff;

}

 

.feedback_swiper  .swiper-pagination{
  text-align: start !important;
  margin-left: 30px;
}


.feedback_swiper  .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  border: 1.5px solid #4e4e4e;
  opacity: 1;
  border-radius: 50%;
  margin: 0 5px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  }
  
  .feedback_swiper .swiper-pagination-bullet-active {
    background-color: #FF9100;
    border: none;
}

.feedback_card{
  height: 100px;
  width: 100%;
  /* overflow: auto; */

  }



  .feedback_card .user_data{
    display: flex;
    align-items: center;
    gap: 20px;
  }


  .feedback_card .user_data .user_image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  .feedback_card .user_data .user_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .feedback_card .user_feedback{
      margin-top: 20px;
      color: rgb(78, 78, 78);
      font-size: 15px;
      font-weight: bolder;
      margin-bottom: 40px;

  }


  
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .feedback_container .feedback_content{
    padding: 0 30px;
    flex-direction: column;
    gap: 20px;
}

.feedback_container .left ,
.feedback_container .right {
  width: 100%;
}

.feedback_container .feedback_title .title {
  font-size: 25px;

}

.feedback_container .left p {
  font-size: 10px;
  }

  .feedback_btn{
    margin-top: 15px;
    padding: 7px 14px;
    font-size: 10px;
    border-radius: 4px;
  }

  
  .feedback_card{
     padding: 15px;
  }

  .feedback_swiper  .swiper-pagination{
    margin-left: 15px;
  }
  
  
.feedback_card{
  height: 150px !important;
  /* overflow: auto; */

  }
  .feedback_card .user_data{

    gap: 15px;
  }

  
  .feedback_card .user_data .user_image{
    width: 30px;
    height: 30px;
  }

  .feedback_card .user_feedback{
    margin-top: 16px;
    font-size: 8px;
    margin-bottom: 20px;
}
  

  
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
 
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Extra large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra extra large devices (larger desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}
