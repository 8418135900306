.checkout_container {
  background-color: #ee701e;
  padding: 40px;
  /* margin-bottom: 20px; */
}

.checkout_products {
  display: flex;

  flex-direction: column;
}

.checkout_products .checkout_prod {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

.checkout_products .checkout_prod .prod_image_name {
  display: flex;
  align-items: center;

  gap: 15px;
}

.checkout_products .checkout_prod .prod_image_name .prod_image {
  position: relative;

}

.checkout_products .checkout_prod .count_padge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000d2;

  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.checkout_products .checkout_prod .prod_image_name img {
  width: 100px;
  background-color: #fff;
  border-radius: 10px;
}


.os_right_side.aos-init.aos-animate p {
  width: 500px;
}


.fb_content.aos-init.aos-animate {
  width: 95%;
  margin: auto;
}