.form_group_container{
  
}



.form_group_container form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 1.25rem; */
}

.form_group_container .custom_inputs_group {
   /* display: grid; */
   gap: 25px;
   row-gap: 12px;
}

.form_group_container .custom_inputs_group div {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.form_group_container .custom_inputs_group input ,
.form_group_container .custom_inputs_group select {
    padding: 12px 0.75rem;
    color : #6e707e;
    border: 1px solid #d1d3e2;
    border-radius: 3px;
    transition: all 0.3s;
    outline: none;
}


.form_group_container .custom_inputs_group label {
    color: #6e707e;
    font-size: 14px;
}

.form_group_container .custom_inputs_group label span {
    color: #e74a3b ;
}

.form_group_container  input:focus {
    border-color: #bac8f3;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}



.custom_form_buttons{
  display: flex;
  align-items: center;
  gap: 5px;
}